.popup {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  max-width: 520px;
  margin: 0 auto;



  .form-control {
    border: none;
    padding: 0.5rem 1rem;
    background-color: #eeeff3;
  }

  .bg-primary {
    background-color: #62a0f3 !important;
  }

  .svg > * {
    fill: #ffffff;
  }





  .btn-primary {
    background-color: #62a0f3;
  }

  .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: 0.5rem 1.25rem;
  }
}
