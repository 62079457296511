button.stepper-step {
  background-color: transparent;
  outline: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  padding: 5px 0;
}

button.stepper-step.selected {
  color: #009ef7;
  border-bottom: 3px solid #009ef7;
}
